<template>
    <div class="mt-2 mb-2">
        <div class="">
            <div class="flex mt-3 items-center">
                <div class="flex-initial relative transition-transform duration-300 ease-in-out flex items-center">
                    <span 
                        v-bind:class="{ 'bg-green-50': checked, 'bg-red-50': !checked }" 
                        class="block w-14 h-8 border border-grey rounded-full shadow-inner flex items-center justify-center">
                        <span v-if="checked" class="text-green-600" style="position: relative; top: 1px;">✔️</span>
                        <span v-else class="text-gray-600" style="position: relative; top: 1px;">➖</span>
                    </span>
                </div>
                <div class="flex-grow ml-3">
                    <div class="text-lg sm:text-lg">
                        {{ optin.name }}
                    </div>
                    <div v-if="hasDescription(optin)">
                        <i>
                            {{ optin.description }}
                        </i>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
        }
    },
    props: {
        optin: {
        type: Object,
        required: true
        }
    },
    computed: {
        title: function() {
            if (this.optin.name) {
                return this.optin.name;
            }
            return "";
        },
        checked: function() {
            if (this.optin.typeid === 40002) {
                return (this.optin.status === 7603);
            }
            return (this.optin.status === 7602);
        }
    },
    methods: {
        hasDescription(optin) {
            if (optin.description === '-') {
                return false;
            }
            return !!optin.description;
        },        
    },
}
</script>
<style scoped>
.unchecked {
    @apply bg-inactive;
}

.checked {
    @apply bg-primary transform translate-x-full;
}
</style>