<template>
    <div class="w-full rounded-lg bg-gray-100 p-4">
      <div class="flex justify-between">
        <h2 class="text-2xl mb-4">Persoonlijke gegevens</h2>
        <button
          @click="$router.push(contactInfoRoute)"
          class="py-2 px-4 capitalize tracking-wide bg-primary hover:bg-primary-light text-white font-medium rounded
                  transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-110"
        >
          <span v-if="!noInfo">Bewerken</span>
          <span v-else>Invullen</span>
        </button>
      </div>
  
      <p v-if="noInfo">
        Vul hier je gegevens in zodat we je kunnen bereiken.
      </p>
  
      <div class="personal-info">
        <div class="info-label">Naam:</div>
        <div class="info-value">{{ fullName }}</div>
  
        <div v-if="config.contactinfo.showId" class="info-block">
          <div class="info-label">Gastnummer:</div>
          <div class="info-value">{{ this.$auth.user }}</div>
        </div>
  
        <div v-if="contact.addresses.length > 0">
            <div class="info-label">Adres:</div>
            <div
            v-for="(address, index) in contact.addresses"
            :key="address.id"
            class="info-value"
            v-html="fullAddress(index)"
            ></div>
        </div>
          
        <div
          v-for="(phonenumber) in contact.phonenumbers"
          :key="phonenumber.id"
          class="info-block"
        >
          <div class="info-label">Telefoonnummer:</div>
          <div class="info-value">{{ phonenumber.number }}</div>
        </div>
  
        <div v-if="contact.contact && contact.contact.birthdate" class="info-block">
          <div class="info-label">Geboortedatum:</div>
          <div class="info-value">{{ contact.contact.birthdate | friendlyDate }}</div>
        </div>
  
        <div v-if="contact.contact && contact.contact.languagecode" class="info-block">
          <div class="info-label">Taal:</div>
          <div class="info-value">{{ contact.contact.languagecode }}</div>
        </div>
  
        <div v-for="field in customfields" :key="field.id" class="info-block">
            <div class="info-label">{{ field.caption }}:</div>
            <div class="info-value">{{ getFieldValue(field) }}</div>
        </div>
      </div>
    </div>
  </template>

<script>
import { mapGetters } from 'vuex'
const moment = require("moment")
require('moment/locale/nl');
moment.locale('nl');

export default {
    props: {
        contact: {
            type: Object,
            required: true
        }
    },
    computed: {
        ...mapGetters(["config"]),
        contactInfoRoute() {
            if (this.config.contactinfo.dynamic) {
                return "/contactinfo2";
            } else {
                return "/contactinfo";
            }
        },
        firstnameLabel: function() {
            if (this.config.initials) {
                return "Voorletter(s)";
            }
            return "Voornaam";
        },
        title: function() {
            if (!this.contact || !this.contact.contact) {
                return "";
            }
            const title = this.$store.state.contactconfig.contacttitles.find(t => t.id === this.contact.contact.customertitleid)
            if (!title) {
                return "";
            }
            return title.name;
        },
        fullName: function() {
            if (!this.contact || !this.contact.contact) {
                return "";
            }          
            const values = [
                this.title,
                this.contact.contact.firstname,
                this.contact.contact.middlename,
                this.contact.contact.lastname,
            ].filter(s => !!s)
            return values.join(' ')
        },
        noPhonenumber: function() {
            if (this.contact.phonenumbers.length === 0) {
                return true;
            }
            if (this.contact.phonenumbers[0].number === "") {
                return true;
            }
            return false;
        },
        noInfo: function() {
            if (!this.contact || !this.contact.contact) {
                return true;
            }               
            const values = [
                this.contact.contact.firstname,
                this.contact.contact.middlename,
                this.contact.contact.lastname,                
                this.contact.contact.birthdate,
                this.contact.contact.languagecode,
            ].filter(s => !!s)
            return (values.join('') === '' && this.contact.addresses.length === 0 && this.noPhonenumber);
        },
        customfields: function() {
            const activatedFields = this.config.contactinfo.customFields;
            return this.$store.state.customfields.fields
                .map(f => {
                    f.fullkey = `c_${f.key}`;
                    return f;
                })
                .filter(f => {
                    if (!activatedFields.includes(f.key)) return false;
                    const value = this.contact.contact[f.fullkey];
                    if (!value) return false;
                    if (Array.isArray(value) && value.length === 0) return false;
                    return true;
                })
                // sort in order of activatedFields
                .sort((a, b) => activatedFields.indexOf(a.key) - activatedFields.indexOf(b.key));

        }
    },  
    methods: {
        addressLine1: function(index) {
            if (!this.contact || !this.contact.addresses) {
                return "";
            }
            const values = [
                this.contact.addresses[index]['street1'],
                this.contact.addresses[index]['street2'],
                this.contact.addresses[index]['street3'],
            ].filter(s => !!s)
            return values.join(' ')
        },
        addressLine2: function(index) {
            if (!this.contact || !this.contact.addresses) {
                return "";
            }          
            const values = [
                this.contact.addresses[index]['zip'],
                this.contact.addresses[index]['city'],
            ].filter(s => !!s)
            return values.join(' ')
        },
        fullAddress: function(index) {
            const values = [
                this.addressLine1(index),
                this.addressLine2(index),
            ].filter(s => !!s)
            return values.join('<br/>')
        },
        getFieldValue(field) {
            if (field.fieldtype.startsWith('select')) {
                const fieldValue = this.contact.contact[field.fullkey];
                
                if (Array.isArray(fieldValue)) {
                return fieldValue.map(id => {
                    const option = field.customfieldvalues.find(option => option.id === id);
                    return option ? option.caption : id;
                }).join(', ');
                }

                const option = field.customfieldvalues.find(option => option.id === fieldValue);
                return option ? option.caption : fieldValue;
            }

            if (field.fieldtype === 'boolean') {
                return this.contact.contact[field.fullkey] ? 'Ja' : 'Nee';
            }

            if (field.fieldtype === 'date') {
                if (!this.contact.contact[field.fullkey]) {
                    return "";
                }
                return moment(this.contact.contact[field.fullkey]).format('D MMMM YYYY');
            }

            return this.contact.contact[field.fullkey];
        }        
    },
    filters: {
        friendlyDate: function (date) {
            if (!date) {
                return "";
            }
            return moment(date).format('D MMMM YYYY')
        },
    },
}
</script>

<style>
.info-label {
  font-weight: bold;
  color: #333;
}

.info-value {
  margin-left: 8px;
  color: #666;
}

.info-block {
  margin: 10px 0;
}

.personal-info {
  display: flex;
  flex-direction: column;
}
</style>