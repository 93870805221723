<template>
    <div class="container w-full lg:w-5/6 xl:w-3/4 2xl:w-1/2 mx-auto flex flex-col py-6 px-3">
        <h2 class="text-3xl text-primary md:text-4xl font-medium mb-2">
            Mijn gegevens
        </h2>
        <PersonalInfo
            v-if="showInfo" 
            :contact="contact"
            class="mb-4"/>
        <PersonalOptins 
            v-if="showOptins" 
            :optins="optins.contactoptins"
            class="mb-4"/>
        <div v-if="isLoading">
            <font-awesome-icon icon="spinner" spin="spin" size="3x" class="text-primary block m-auto"/>
        </div>
    </div> 
</template>

<script>
import { mapGetters } from 'vuex'
import PersonalInfo from '../components/PersonalInfo.vue'
import PersonalOptins from '../components/PersonalOptins.vue'

export default {
    name: 'Home',
    title: 'Mijn Gegevens',
    components: {
        PersonalInfo,
        PersonalOptins,
    },
    data() {
        return {
            isLoading: false,
        }
    },
    computed: {
        ...mapGetters(["config", "contact", "optins"]),
        showInfo: function() {
            return this.isLoading === false;
        },
        showOptins: function() {
            return this.isLoading === false && this.config.optins.active;
        }
  },
  methods: {
  },
  filters: {
  },
  async created() {
    this.isLoading = true;
    try {
        if (!this.$store.state.contact.contact) {
            await this.$store.dispatch('setContactInfo');
        }
        if (this.$store.state.contactconfig.contacttitles.length === 0) {
            await this.$store.dispatch('setContactConfig');
        }
        if (this.$store.state.customfields.loaded !== true) {
            await this.$store.dispatch('setCustomFields');
        }
        if (this.$store.state.optins.loaded === false && this.config.optins.active) {
            await this.$store.dispatch('setOptins');
        }
    } catch (ex) {
        this.error = true;
    } finally {
        this.isLoading = false;
    }
  },
  async mounted () {
    this.$mixpanel.track('pageview', {
        distinct_id: this.$auth.user,
        account: this.config.shortname,
        page: "Personal",
    })
  },
}
</script>
