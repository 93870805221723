<template>
    <div class="w-full rounded-lg bg-gray-100 p-4">
        <div class="flex">
            <div class="flex-grow">
                <h2 class="text-2xl mb-4">Voorkeuren</h2>
            </div>
            <div class="flex-initial">
                <div>
                    <button
                        @click="$router.push('optins')"
                        class="py-2 px-4 capitalize tracking-wide bg-primary hover:bg-primary-light text-white font-medium rounded
                            transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-110">
                        Bewerken
                    </button>
                </div>
            </div>
        </div>
        <Optin
            v-for="(optin, index) of optins" 
            v-bind:key="index"
            :optin="optin">
        </Optin>
    </div>
</template>

<script>
import Optin from "./Optin.vue"
export default {
    components: {
        Optin,
    },
    props: {
        optins: {
            type: Array,
            required: true
        }
    },
}
</script>